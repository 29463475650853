export const trebuchetMsFontStyle = {
  fontFamily: 'Trebuchet MS',
};

export const darkBlueFontColorStyle = {
  color: 'darkblue',
};

export const inputFormLabelStyle = {
  ...trebuchetMsFontStyle,
  ...darkBlueFontColorStyle,
  fontSize: 16,
  paddingBottom: 10,
};

export const helperTextStyle = {
  ...trebuchetMsFontStyle,
  fontSize: 9,
};

export const muiSelectTextStyle = {
  ...trebuchetMsFontStyle,
  fontSize: 14,
};

export const mainPageHeaderStyle = {
  fontSize: 24,
  color: '#4A148C',
  background: '#F3E5F5',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 10,
  height: 40,
};

export const mainPageCardHeaderStyle = {
  ...trebuchetMsFontStyle,
  fontSize: '2.4rem',
  paddingTop: '1.2rem',
  color: '#1A237E',
  fontWeight: 600,
  textAlign: 'center'
}

export const lightBlueLinkStyle = {
  textDecoration: 'none',
  color: '#1E88E5'
}