
export abstract class GlobalConstants {
  public static TOP_HEADER_ID = 'top_header';
  public static API_URL_CACHE_KEY: string = 'apiUrl';
  public static TEXTBOX_NUMERIC_VALUE_TYPE = 'number';
  public static OPERATORS = ['<', '>', '<=', '>=', '=', '!=', '<>'];
  public static TABLE_CHUNK_SIZE = 15;
}

export abstract class StringConstants {
  public static EMPTY_STRING = '';
  public static DATA_TEST_ID_PREFIX = 'test_id';
  public static DROP_DOWN_CLEAR_VALUE = '-- Clear --';
}
export abstract class ComponentRules {
  public static VALUE_TYPE = 'valueType';
  public static HELPER_INFO = 'helperInfo';
  public static REQUIRED = 'required';
}

export enum ValueType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL'
}
