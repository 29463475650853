import { Box, Card, CardActionArea, CardContent, List, ListItem, Typography } from '@mui/material';
import { trebuchetMsFontStyle } from '../../constants/ReactStyles';
import { routeConfig } from '../../generated/routeConfig';
import { RouteConfig } from '../navigation/NavigationConfig';
import './styles.scss';

export default function PluginsMainPage() {
  const list_item_data: { [key: string]: RouteConfig } = routeConfig();
  console.log(list_item_data);
  return (
    <Box id="plugin-container" style={{ width: '100%', paddingTop: 10 }}>
      <Typography
        component="div"
        variant="h4"
        sx={{
          ...trebuchetMsFontStyle,
          fontSize: 36,
          fontWeight: 600,
          textAlign: 'center',
          alignContent: 'center',
          color: '#00003E',
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        Browse Our Catalog
      </Typography>
      <List style={{ paddingLeft: 50, paddingRight: 50 }}>
        {Object.values(list_item_data).map((route) => (
          <ListItem>
            <Card variant="elevation" id="plugin-cards">
              <CardActionArea href={route.href}>
                <CardContent sx={{ alignContent: 'stretch', alignItems: 'stretch' }}>
                  <Typography component="div" variant="h4" sx={{ ...trebuchetMsFontStyle, fontSize: 20 }}>
                    {route.label}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
