import Box from '@amzn/awsui-components-react/polaris/box';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import LightHouse from '../../assets/LightHouse.png';
import { lightBlueLinkStyle, mainPageCardHeaderStyle } from '../../constants/ReactStyles';
import './styles.scss';

export default function WelcomePage() {
  return (
    <div id="home-page-main-container">
      <Grid container>
        <Grid item xs={4}>
          <Box padding={{ left: 'xl' }}>
            <img src={LightHouse} alt="AlaCarte" id="light-house" />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <div id="alc-title">
            <Typography
              variant="h1"
              style={{
                fontFamily: 'Gloria Hallelujah',
                paddingTop: 80,
                paddingLeft: 200,
                color: '#1A237E',
                fontSize: 80,
              }}
            >
              AlaCarte{' '}
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontFamily: 'Gloria Hallelujah',
                paddingTop: 20,
                paddingLeft: 300,
                color: '#0D47A1',
                fontSize: 40,
              }}
            >
              You Choose, We Deliver
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ padding: 20 }}>
        <Grid item xs={4} style={{ padding: 20 }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              AlaCarte in a Nutshell
            </Typography>
            <CardContent>
              AlaCarte is a serverless full-stack interface, which allows you to fetch, modify and create your data from
              UI (Frontend) and API (Backend). UI is a Single Page Application (SPA) hosted on{' '}
              <a href="https://aws.amazon.com/cloudfront/" target="_blank" rel="noreferrer" style={lightBlueLinkStyle}>
                CloudFront
              </a>{' '}
              and API is an integration of{' '}
              <a
                href="https://docs.aws.amazon.com/apigateway/latest/developerguide/set-up-lambda-proxy-integrations.html"
                target="_blank"
                rel="noreferrer"
                style={lightBlueLinkStyle}
              >
                AWS API Gateway with Lambda Proxy
              </a>
              .
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ padding: 20 }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              Plug & Play{' '}
            </Typography>
            <CardContent>
              AlaCarte runs on Plug & Play design. You just need an AWS Lambda and voilà, you can integrate with
              AlaCarte. Your Lambda, your data and your technology, we don't have any specific demands from your side.
              We also have out of the box plugins, if you want to use them
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ padding: 20 }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              Out of the Box Interface
            </Typography>
            <CardContent>
              Ready to use UI interface and API interface (Clients) in multiple languages. Define a{' '}
              <a href="https://smithy.io/2.0" target="_blank" style={lightBlueLinkStyle} rel="noreferrer">
                Smithy
              </a>{' '}
              Model for your operations and request / response to integrate with our service and you are good to go.
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ padding: 20 }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              Top Notch Security
            </Typography>
            <CardContent>
              Midway protection on the UI and SigV4 / IAM Authentication on API. Not only that, but you also get{' '}
              <a href="https://bindles.amazon.com/lock/new" target="_blank" rel="noreferrer" style={lightBlueLinkStyle}>
                BindleLock
              </a>{' '}
              selective authorisation based on entities like employee, team, AWS account or IAM Roles.
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ padding: 20 }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              Out of the Box Infrastructure
            </Typography>
            <CardContent>
              Want to integrate but don't have any plugins ready, don't worry we will take care. We provide a custom{' '}
              <a
                href="https://code.amazon.com/packages/AlaCarteCDKConstruct/trees/mainline"
                target="_blank"
                rel="noreferrer"
                style={lightBlueLinkStyle}
              >
                AlaCarte CDK Construct
              </a>{' '}
              to help you deploy your Lambda, with the ability to have VPC and Superstar configured. No need to stress
              about CDK code. We also provide you a{' '}
              <a
                href="https://code.amazon.com/packages/AlaCarteConfigurations/blobs/mainline/--/alc-onboard-wizard.sh"
                target="_blank"
                rel="noreferrer"
                style={lightBlueLinkStyle}
              >
                custom script
              </a>{' '}
              to setup Version Set, Pipeline, Bindle and code packages.
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ padding: 20 }}>
          <Card variant="elevation" id="feature-cards">
            <Typography variant="h3" sx={mainPageCardHeaderStyle}>
              Incremental Development
            </Typography>
            <CardContent>
              You can use our existing UI Templates to host your UI. In case those are not appealing to you. You want to
              build your own UI, then come along and contribute to our UI templates or build a custom page on
              React-Redux. The templates you build will be available for use to everyone on AlaCarte, a lasting
              impression of your contribution
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
