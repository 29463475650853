import { Box, Grid } from '@mui/material';
import LightHouse from '../assets/LightHouse.png';
import HeaderItem from './misc/HeaderItem';
import './styles.scss';

export default function CommonHeader() {
  return (
    <div id="main-header">
      <Grid container>
        <Grid item xs={8}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <a href="/" style={{ textDecoration: 'none' }}>
              <div>
                <img src={LightHouse} alt="ALC" id="light-house-icon" />
              </div>
            </a>
            <HeaderItem text="Catalog" href="#/catalog" />
            <HeaderItem
              text="Architecture"
              href="https://w.amazon.com/bin/view/AB/TestDataService/Architecture"
              newtab={true}
            />
            <HeaderItem
              text="UX Design"
              href="https://w.amazon.com/bin/view/AB/TestDataService/Frontend"
              newtab={true}
            />
            <HeaderItem
              text="API Design"
              href="https://w.amazon.com/bin/view/AB/TestDataService/Backend"
              newtab={true}
            />
            <HeaderItem
              text="Onboarding"
              href="https://w.amazon.com/bin/view/AB/TestDataService/Onboarding"
              newtab={true}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
            <HeaderItem
              text="Avengers"
              href="https://w.amazon.com/bin/view/B2B/B2B_Quality_Assurance/AB-AVENGERS/"
              newtab={true}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
