import { Fragment, Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { routeConfig } from '../../generated/routeConfig';
import { FrontendConfig } from '../../models/FrontendConfig';
import { resetResponseObject } from '../../redux/global/axiosSlice';
import {
  resetConfigError,
  updateConfigError,
  updatePluginFunction,
  updateDataProps,
  updatePluginProvider,
} from '../../redux/global/pluginProviderSlice';
import { RootState } from '../../redux/store';
import { makeAsyncPostRequestToService } from '../../services/ServiceCalls';
import PluginsApp from '../routes/PluginsApp';
import WelcomeApp from '../routes/WelcomeApp';
import { RouteConfig } from './NavigationConfig';

const route_config: { [key: string]: RouteConfig } = routeConfig();

const getRouteComponent = (name: string): React.ComponentType => {
  const file_path = /^.+.tsx$/.test(name) ? name : `${name}.tsx`;
  return lazy(() =>
    import(/* webpackMode: "lazy" */ `../routes/${file_path}`).catch(() => import('../routes/Error404App'))
  );
};

export default function Routes() {
  const plugin_provider = useSelector((state: RootState) => state.pluginProvider.plugin_provider);
  const data_props = useSelector((state: RootState) => state.pluginProvider.data_props);
  const dispatch = useDispatch();

  useEffect(() => {
    const hash = decodeURI(window.location.hash);
    const url_ext = hash.split('/')[1];
    if (url_ext) {
      const url_data_provider = Object.entries(route_config).find(([key, value]) => value.href === hash);
      if (url_data_provider?.[0] && url_data_provider?.[0] !== plugin_provider) {
        console.log(`Routing to different page - ${url_data_provider?.['href']}`);
        dispatch(updatePluginProvider(url_data_provider?.[0]));
        dispatch(updatePluginFunction(undefined));
        dispatch(resetResponseObject());
      }
      if (plugin_provider && !data_props[plugin_provider]) {
        console.log(`Web configuration absent for - ${plugin_provider}, fetching from server >>>>>`);
        makeAsyncPostRequestToService('webConfiguration', { dataProvider: plugin_provider }).then((response) => {
          const response_data = response.response?.data as { [key: string]: FrontendConfig };
          if (response.error) {
            dispatch(updateConfigError(response.error));
          } else {
            dispatch(resetConfigError());
            dispatch(updateDataProps({ ...data_props, [plugin_provider]: response_data }));
          }
        });
      }
    } else {
      console.log('Redirecting to Home Page!!');
      dispatch(updatePluginProvider(''));
      dispatch(updatePluginFunction(undefined));
      dispatch(resetResponseObject());
    }
  });

  // Suspense fallback for lazyload is kept as 'null' for now, if needed a fallback page can be created later for the case
  return (
    <Fragment>
      <Suspense fallback={null}>
        <Route exact key={'AlaCarte'} path="/" component={WelcomeApp} />
        <Route key={'Plugins'} path="/catalog" component={PluginsApp}/>
        {Object.values(route_config).map((route) => (
          <Route
            exact
            key={route.label.toLocaleUpperCase()}
            path={route.href.substring(1)}
            component={getRouteComponent(route.route)}
          />
        ))}
      </Suspense>
    </Fragment>
  );
}
